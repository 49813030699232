import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getEstimateCard(action) {
  const { payload } = action;
  const { practiceId, estimateId } = payload;

  try {
    const params = { order: "ASC" };
    const url = `/practices/${practiceId}/estimates/${estimateId}/treatment-plans`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.ESTIMATE_CARDS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.ESTIMATE_CARDS_FAILED,
      message: error.message
    });
  }
}

function* getEstimateCardSaga() {
  yield takeEvery(actionType.ESTIMATE_CARDS_REQUESTED, getEstimateCard);
}

function* getStages(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Included"
    };
    const url = `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.GET_STAGES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.GET_STAGES_FAILED,
      message: error.message
    });
  }
}

function* getStagesSaga() {
  yield takeEvery(actionType.GET_STAGES_REQUESTED, getStages);
}

//adjustment list
function* fetchAdjustment(action) {
  const { payload } = action;
  const { estimateId, estimateTpId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const url = `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/adjustments`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.ADJUSTMENT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.ADJUSTMENT_LIST_FAILED, message: error.message });
  }
}

function* fetchAdjustmentSaga() {
  yield takeEvery(actionType.ADJUSTMENT_LIST_REQUESTED, fetchAdjustment);
}

//get optional
function* fetchOptional(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      category: "Optional"
    };
    const url = `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.OPTIONAL_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.OPTIONAL_LIST_FAILED, message: error.message });
  }
}

function* fetchOptionalSaga() {
  yield takeEvery(actionType.OPTIONAL_LIST_REQUESTED, fetchOptional);
}
//get necessary
function* fetchNecessary(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      category: "Necessary"
    };
    const url = `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.NECESSARY_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.NECESSARY_LIST_FAILED, message: error.message });
  }
}

function* fetchNecessarySaga() {
  yield takeEvery(actionType.NECESSARY_LIST_REQUESTED, fetchNecessary);
}

// get estimate list
function* fetchFertilityAccessPrograms(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const url = `/practices/${payload.practiceId}/estimates/${payload.estimateId}/estimateTreatmentPlans/${payload.estimateTpId}/fertilityAccessPrograms`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.FERTILTY_ACCESS_PROGRAMS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.FERTILTY_ACCESS_PROGRAMS_FAILED,
      message: error.response.data
    });
  }
}

function* fetchFertilityAccessProgramsSaga() {
  yield takeEvery(actionType.FERTILTY_ACCESS_PROGRAMS_REQUESTED, fetchFertilityAccessPrograms);
}

function* saveProgramSelections(action) {
  const { payload } = action;
  const { practiceId, patientId, estimateId, body } = payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/patients/${patientId}/estimates/${estimateId}/programSelections`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.UPADATE_PROGRAM_SELECTIONS_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionType.UPADATE_PROGRAM_SELECTIONS_FAILED,
      errorMsg: (err && err.response && err.response.data) || err.message
    });
  }
}
function* saveProgramSelectionsSaga() {
  yield takeEvery(actionType.UPADATE_PROGRAM_SELECTIONS_REQUESTED, saveProgramSelections);
}
//get Practice pgta data
function* getPracticePGTADataSaga(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const url = `/practices/${payload}/pgtas`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({ type: actionType.PRACTICE_PGTA_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: actionType.PRACTICE_PGTA_DATA_FAILED, messsage: error.message });
  }
}
function* fetchPracticePGTADataSaga() {
  yield takeEvery(actionType.PRACTICE_PGTA_DATA_REQUESTED, getPracticePGTADataSaga);
}

//update review estimate status
function* updateReviewStatus(action) {
  const { payload } = action;
  const { estimateId, estimateTpId } = payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/patientReview`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.REVIEW_ESTIMATE_SUCCESS,
      payload: response.data
    });
  } catch (err) {
    yield put({
      type: actionType.REVIEW_ESTIMATE_FAILED,
      errorMsg: err.message
    });
  }
}
function* updateReviewStatusSaga() {
  yield takeEvery(actionType.REVIEW_ESTIMATE_REQUESTED, updateReviewStatus);
}

// get estimate notes
function* getFinancialEstimateNote(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/estimates/${payload.estimateId}/notes`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.FINANCIAL_ESTIMATE_NOTES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.FINANCIAL_ESTIMATE_NOTES_FAILED,
      message: error.message
    });
  }
}

function* getFinancialEstimateNoteSaga() {
  yield takeEvery(actionType.FINANCIAL_ESTIMATE_NOTES_REQUESTED, getFinancialEstimateNote);
}

export default function* estimateSaga() {
  yield all([
    getEstimateCardSaga(),
    getStagesSaga(),
    fetchAdjustmentSaga(),
    fetchOptionalSaga(),
    fetchNecessarySaga(),
    fetchFertilityAccessProgramsSaga(),
    saveProgramSelectionsSaga(),
    fetchPracticePGTADataSaga(),
    updateReviewStatusSaga(),
    getFinancialEstimateNoteSaga()
  ]);
}
